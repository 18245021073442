import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        // console.log("To:", to.query);
        /*
        if (to.name === 'filter' && to.name === from.name) {
            return false;
        } else if (to.name === 'home' && to.name === from.name) {
            return false;
        } else if (to.name === 'auctions' && to.name === from.name) {
            return false;
        } else {
            return { x: 0, y: 0 };
        }*/
        if (Object.keys(to.query).length > 0 && to.name === from.name) {
            return false;
        } else {
            return { x: 0, y: 0 };
        }
        // if (Object.keys(store.state.home.filter).length == 0) {
        //     // console.log("store.state.home.filter.length:", Object.keys(store.state.home.filter).length);
        //     return { x: 0, y: 0 }
        // }
    },
    routes: [{
            path: '/',
            name: 'home',
            component: () =>
                import ('@/components/Pages/HomePage.vue'),
            meta: {
                pageTitle: 'Home',
                guest: true,
                breadcrumb: [{
                    text: 'Home',
                    active: true,
                }, ],
            },
        }, {
            path: '/home',
            name: 'home2',
            component: () =>
                import ('@/components/Pages/HomePage.vue'),
            meta: {
                pageTitle: 'Home',
                guest: true,
                breadcrumb: [{
                    text: 'Home',
                    active: true,
                }, ],
            },
        },
        {
            path: '/auctions',
            name: 'auctions',
            component: () =>
                import ('@/components/Pages/AuctionsPage.vue'),
            meta: {
                pageTitle: 'Auctions',
                guest: true,
                breadcrumb: [{
                    text: 'Auctions',
                    active: true,
                }, ],
            },
        },
        {
            path: '/filter',
            name: 'filter',
            component: () =>
                import ('@/components/Pages/FilterPage.vue'),
            meta: {
                pageTitle: 'Filter',
                guest: true,
                breadcrumb: [{
                    text: 'Filter',
                    active: true,
                }, ],
            },
        },
        {
            path: '/sales',
            name: 'sales',
            component: () =>
                import ('@/components/Pages/SalesPage.vue'),
            meta: {
                pageTitle: 'Sales',
                guest: true,
                breadcrumb: [{
                    text: 'Sales',
                    active: true,
                }, ],
            },
        },
        {
            path: '/packages',
            name: 'packages',
            component: () =>
                import ('@/components/Pages/PackagesPage.vue'),
            meta: {
                pageTitle: 'Packages',
                guest: true,
                breadcrumb: [{
                    text: 'Packages',
                    active: true,
                }, ],
            },
        },
        {
            path: '/subsribe-package/:id',
            name: 'subsribe-package/:id',
            component: () =>
                import ('@/components/Pages/SubscribePackagePage.vue'),
            meta: {
                pageTitle: 'SubscribePackage',
                guest: true,
                breadcrumb: [{
                    text: 'SubscribePackage',
                    active: true,
                }, ],
            },
        },
        {
            path: '/car/:slug',
            name: 'car',
            component: () =>
                import ('@/components/Pages/CarPage.vue'),
            meta: {
                pageTitle: 'Car',
                guest: true,
                breadcrumb: [{
                    text: 'Car',
                    active: true,
                }, ],
            },
        },
        {
            path: '/auction/:slug',
            name: 'auction',
            component: () =>
                import ('@/components/Pages/AuctionPage.vue'),
            meta: {
                pageTitle: 'Auction',
                breadcrumb: [{
                    guest: true,
                    text: 'Auction',
                    active: true,
                }, ],
            },
        },
        /*{
            path: '/account',
            name: 'account',
            children: [{
                path: '',
                name: 'user',
                children: [{

                }],
                component: () =>
                    import ('@/components/Pages/ProfileSection.vue'),
                meta: {
                    pageTitle: 'Profile',
                    breadcrumb: [{
                        text: 'Profile',
                        active: true,
                    }, ],
                },
            }],
        }, */

        {
            path: '/profile',
            name: 'profile',
            component: () =>
                import ('@/components/Pages/ProfilePage.vue'),
            meta: {
                pageTitle: 'Profile',
                guest: false,
                breadcrumb: [{
                    text: 'Profile',
                    active: true,
                }, ],
            },
        },
        {
            path: '/wallet',
            name: 'wallet',
            component: () =>
                import ('@/components/Pages/WalletPage.vue'),
            meta: {
                pageTitle: 'Wallet',
                guest: false,
                breadcrumb: [{
                    text: 'Wallet',
                    active: true,
                }, ],
            },
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () =>
                import ('@/components/Pages/DashboardPage.vue'),
            meta: {
                pageTitle: 'Dashboard',
                guest: false,
                breadcrumb: [{
                    text: 'Dashboard',
                    active: true,
                }, ],
            },
        },
        {
            path: '/invoice/:id',
            name: 'invoice',
            component: () =>
                import ('@/components/Pages/InvoicePage.vue'),
            meta: {
                pageTitle: 'Invoice',
                guest: false,
                breadcrumb: [{
                    text: 'Invoice',
                    active: true,
                }, ],
            },
        },
        {
            path: '/active-auctions',
            name: 'active-auctions',
            component: () =>
                import ('@/components/Pages/ActiveAuctionsPage.vue'),
            meta: {
                pageTitle: 'ActiveAuctions',
                guest: false,
                breadcrumb: [{
                    text: 'ActiveAuctions',
                    active: true,
                }, ],
            },
        },
        {
            path: '/win-auctions',
            name: 'win-auctions',
            component: () =>
                import ('@/components/Pages/WinAuctionsPage.vue'),
            meta: {
                pageTitle: 'WinAuctions',
                guest: false,
                breadcrumb: [{
                    text: 'WinAuctions',
                    active: true,
                }, ],
            },
        },
        {
            path: '/notifications',
            name: 'notifications',
            component: () =>
                import ('@/components/Pages/NotificationsPage.vue'),
            meta: {
                pageTitle: 'Notifications',
                guest: false,
                breadcrumb: [{
                    text: 'Notifications',
                    active: true,
                }, ],
            },
        },
        {
            path: '/favourite',
            name: 'favourite',
            component: () =>
                import ('@/components/Pages/FavouritePage.vue'),
            meta: {
                pageTitle: 'favourite',
                guest: false,
                breadcrumb: [{
                    text: 'favourite',
                    active: true,
                }, ],
            },
        },
        {
            path: '/my-payments',
            name: 'my-payments',
            component: () =>
                import ('@/components/Pages/MyPaymentPage.vue'),
            meta: {
                pageTitle: 'mypayments',
                guest: false,
                breadcrumb: [{
                    text: 'mypayments',
                    active: true,
                }, ],
            },
        },
        {
            path: '/finance',
            name: 'finance',
            component: () =>
                import ('@/components/Pages/ProfilePage.vue'),
            meta: {
                pageTitle: 'finance',
                guest: false,
                breadcrumb: [{
                    text: 'finance',
                    active: true,
                }, ],
            },
        },
        {
            path: '/shipping',
            name: 'shipping',
            component: () =>
                import ('@/components/Pages/ShippingPage.vue'),
            meta: {
                pageTitle: 'shipping',
                guest: false,
                breadcrumb: [{
                    text: 'shipping',
                    active: true,
                }, ],
            },
        },
        {
            path: '/contact-us',
            name: 'contact-us',
            component: () =>
                import ('@/components/Pages/ContactUsPage.vue'),
            meta: {
                pageTitle: 'Contact Us',
                guest: true,
                breadcrumb: [{
                    text: 'contact us',
                    active: true,
                }, ],
            },
        },
        {
            path: '/about',
            name: 'about',
            component: () =>
                import ('@/components/Pages/AboutPage.vue'),
            meta: {
                pageTitle: 'About',
                guest: true,
                breadcrumb: [{
                    text: 'About',
                    active: true,
                }, ],
            },
        },
        {
            path: '/create-auctions',
            name: 'CreateAuctions',
            component: () =>
                import ('@/components/Pages/CreateAuctionsPage.vue'),
            meta: {
                pageTitle: store.state.home.appInfo.create_auctions_title_ar,
                guest: true,
                breadcrumb: [{
                    text: 'CreateAuctions',
                    active: true,
                }, ],
            },
        },
        {
            path: '/fqe',
            name: 'fqe',
            component: () =>
                import ('@/components/Pages/FQPage.vue'),
            meta: {
                pageTitle: 'FQ',
                guest: true,
                breadcrumb: [{
                    text: 'FQ',
                    active: true,
                }, ],
            },
        },
        {
            path: '/privacy-policy',
            name: 'privacy',
            component: () =>
                import ('@/components/Pages/PPPage.vue'),
            meta: {
                pageTitle: 'privacy policy',
                guest: true,
                breadcrumb: [{
                    text: 'privacy policy',
                    active: true,
                }, ],
            },
        },
        {
            path: '/terms-and-conditions',
            name: 'terms',
            component: () =>
                import ('@/components/Pages/TermsPage.vue'),
            meta: {
                pageTitle: 'terms and conditions',
                guest: true,
                breadcrumb: [{
                    text: 'terms and conditions',
                    active: true,
                }, ],
            },
        },
        {
            path: '/success',
            name: 'success',
            component: () =>
                import ('@/components/Pages/SuccessPage.vue'),
            meta: {
                pageTitle: 'Success',
                guest: false,
                breadcrumb: [{
                    text: 'Success',
                    active: true,
                }, ],
            },
        },
        // {
        //     path: '/.well-known/apple-developer-merchantid-domain-association',
        //     name: 'ApplePayPage',
        //     component: () =>
        //         import ('@/components/Pages/ApplePayPage.vue'),
        //     props: {
        //         file: '@/@core/apple-developer-merchantid-domain-association'
        //     },
        //     meta: {
        //         pageTitle: 'applepay',
        //         guest: false,
        //         breadcrumb: [{
        //             text: 'applepay',
        //             active: true,
        //         }, ],
        //     },
        // },
        {
            path: '/error',
            name: 'error',
            component: () =>
                import ('@/components/helpers/TestCountDown.vue'),
            meta: {
                pageTitle: 'Error',
                guest: true,
                breadcrumb: [{
                    text: 'Error',
                    active: true,
                }, ],
            },
        },
        {
            path: '/provider/:provider/callback',
            component: () =>
                import ('@/components/Pages/HomePage.vue'),
        },
        /*{
            path: '/login',
            name: 'login',
            component: () =>
                import ('@/views/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () =>
                import ('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },*/
    ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from, next) => {
    let token = window.localStorage.getItem('authToken') || null;
    if (to.meta.guest == false && !token) {
        window.location.replace('/');
    }
    if (document.getElementById('nav-close-1') != null) {
        document.getElementById('nav-close-1').click();
    }
    if (document.getElementById('close-nav-2') != null) {
        document.getElementById('close-nav-2').click();
    }
    store.state.home.active_nav = to.name;
    // console.log("store.state.home.active_nav", store.state.home.active_nav);
})

export default router