import axios from "../../axios";

const state = {
    htmlLang: 'ar',
    win_car: 0,
    win_car_id: 0,
    loadedCars: false,
    should_reload: 0,
    current_server_time: 0,
    internetConnectionInterrupted: 0,
    loadedContent: 0,
    lastMsgAlert: '',
    successAlertState: true,
    successAlertMessage: '',
    errorAlertState: true,
    errorAlertMessage: '',
    wraningAlertState: true,
    wraningAlertMessage: '',
    counters: {
        making: 0,
        pending: 0,
        delivering: 0,
    },
    searchFilters: {},
    homePaging: [],
    homePagingPerPage: [],
    homePagingCurrentPage: 0,
    homePagingTotal: [],
    searchBrands: [],
    homeProducts: [],
    homeLatestProducts: [],
    homeBrands: [],
    homeSliders: [],
    homeAuctions: [],
    searchYears: [],
    searchConditions: [],
    searchPrices: [],
    searchBrandModels: [],
    filter: {},
    active_nav: {},
    appInfo: {},
    isLoading: false,
    baseUrl: 'http://127.0.0.1:8000/api/v1',
};

const getters = {
    getCounters: state => state.counters,
    getHomePaging: state => state.homePaging,
    getHomePagingPerPage: state => state.homePagingPerPage,
    getHomePagingCurrentPage: state => state.homePagingCurrentPage,
    getHomePagingTotal: state => state.homePagingTotal,
    getSearchFilters: state => state.searchFilters,
    getHomeAuctions: state => state.homeAuctions,
    getHomeProducts: state => state.homeProducts,
    getHomeLatestProducts: state => state.homeLatestProducts,
    getHomeSliders: state => state.homeSliders,
    getHomeBrands: state => state.homeBrands,
    getSearchBrands: state => state.searchBrands,
    getSearchYears: state => state.searchYears,
    getSearchConditions: state => state.searchConditions,
    getSearchPrices: state => state.searchPrices,
    getSearchBrandModels: state => state.searchBrandModels,
    getFilter: state => state.filter,
};
const mutations = {
    setCounters(state, payload) {
        state.counters = payload;
    },
    setHomePaging(state, payload) {
        let paging = [];
        if (payload.last_page > 1) {
            paging.push({
                text: 1,
                value: 1
            });
        }
        if (payload.last_page > 2) {
            paging.push({
                text: 2,
                value: 2
            });
        }
        if (payload.last_page > 3) {
            paging.push({
                text: 3,
                value: 3
            });
        }
        if (payload.last_page > 5) {
            paging.push({
                text: 'Next',
                value: -1
            });
        }
        if (payload.last_page > 1) {
            paging.push({
                text: payload.last_page,
                value: payload.last_page
            });
        }
        console.log('payload:', paging);
        state.homePaging = paging;
        state.homePagingPerPage = payload.to;
        state.homePagingCurrentPage = payload.current_page;
        state.homePagingTotal = payload.total;
    },
    setSearchFilters(state, payload) {
        state.searchFilters = payload;
    },
    setHomeProducts(state, payload) {
        state.homeProducts = payload;
    },
    setHomeLatestProducts(state, payload) {
        state.homeLatestProducts = payload;
    },
    setHomeBrands(state, payload) {
        state.homeBrands = payload;
    },
    setHomeSliders(state, payload) {
        state.homeSliders = payload;
    },
    setHomeAuctions(state, payload) {
        state.homeAuctions = payload;
    },
    setSearchBrands(state, payload) {

        let finalArray = [{
            label: 'الكل',
            value: ''
        }];
        payload.forEach((o) => {
                finalArray.push(o)
            })
            // state.searchBrands = payload;
        state.searchBrands = finalArray;
    },
    setAppInfo(state, payload) {
        window.localStorage.setItem('appInfo', JSON.stringify(payload))

    },
    setSearchYears(state, payload) {
        let finalArray = [{
            label: 'الكل',
            value: ''
        }];
        payload.forEach((o) => {
                finalArray.push(o)
            })
            // state.searchYears = payload;
        state.searchYears = finalArray;
    },
    setSearchConditions(state, payload) {
        let finalArray = [{
            label: 'الكل',
            value: ''
        }];
        payload.forEach((o) => {
                finalArray.push(o)
            })
            // state.searchConditions = payload;
        state.searchConditions = finalArray;
    },
    setSearchPrices(state, payload) {
        state.searchPrices = payload;
    },
    setSearchBrandModels(state, payload) {
        let finalArray = [{
            label: 'الكل',
            value: ''
        }];
        payload.forEach((o) => {
            finalArray.push(o)
        })
        state.searchBrandModels = finalArray;
        // state.searchBrandModels = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
};
const actions = {
    fetchCounters({ commit }) {
        return axios.get(`/api/admin/orders/orders-counter`)
            .then((response) => {
                commit("setCounters", response.data)
                console.log(response.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchSearchFilters({ commit }) {
        return axios.get(`/search-filters`)
            .then((response) => {
                commit("setSearchFilters", response.data)
                commit("setSearchBrands", response.data.data.brands)
                commit("setSearchYears", response.data.data.years)
                commit("setSearchConditions", response.data.data.conditions)
                commit("setSearchPrices", response.data.data.prices)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchSearchBrandModels({ commit }, item) {
        return axios.get(`/search-filters-models/${item}`)
            .then((response) => {
                commit("setSearchBrandModels", response.data.data.models)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchHomeProducts({ commit }, query) {
        let search = query.search ? query.search : "";
        search ? state.filter = { page: 1 } : state.filter = { page: state.filter.page };
        commit("setFilter", query)
        return axios.get(`/page/home`, { params: state.filter })
            .then((response) => {
                commit("setHomeProducts", response.data.data.products.data)
                commit("setHomeLatestProducts", response.data.data.latest_products)
                commit("setHomeAuctions", response.data.data.auctions)
                commit("setHomeBrands", response.data.data.brands)
                commit("setHomeSliders", response.data.data.sliders)
                commit("setHomePaging", response.data.data.products)
                commit("setAppInfo", response.data.data.settings)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
};

export default {
    namespaced: true,
    name: "home",
    state,
    getters,
    actions,
    mutations,
};